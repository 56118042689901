import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import UsuarioCliente from '../usuario/usuarioCliente';
import UsuarioPerfil from '../usuarioPerfil/usuarioPerfil';
import Grupo from '../grupo/grupo';
import Motorista from '../motorista/motorista';
import MotoristaCnh from '../motorista/motoristaCnh';
import MotoristaCpf from '../motorista/motoristaCpf';
import PremioFaixa from '../premioFaixa/premioFaixa';
import OcorrenciaTipo from '../ocorrenciaTipo/ocorrenciaTipo';
import Ocorrencia from '../ocorrencia/ocorrencia';
import OcorrenciaAcao from '../ocorrenciaAcao/ocorrenciaAcao';
import OcorrenciaStatus from '../ocorrenciaStatus/ocorrenciaStatus';
import OcorrenciaDocumentoViagem from '../ocorrencia/ocorrenciaDocumentoViagem';
import Premiacao from '../premiacao/premiacao';
import Documento from '../documento/documento';
import Operacao from '../operacao/operacao';
import OperacaoPerfil from '../operacaoPerfil/operacaoPerfil';
import OperacaoDespesa from '../operacaoDespesa/operacaoDespesa';
import DocumentoViagem from '../documentoViagem/documentoViagem';
import DocumentoViagemComprovacao from '../documentoViagem/documentoViagemComprovacao';
import DocumentoViagemComprovada from '../documentoViagem/documentoViagemComprovada';
import DocumentoViagemFinalizada from '../documentoViagem/documentoViagemFinalizada';
import Unidade from '../unidade/unidade';
import Veiculo from '../veiculo/veiculo';
import AcessorioTipo from '../acessorioTipo/acessorioTipo';
import AcessorioEvento from '../acessorioEvento/acessorioEvento';
import AcessorioSaida from '../acessorioSaida/acessorioSaida';
import AcessorioEntrada from '../acessorioEntrada/acessorioEntrada';
import AcessorioTransferencia from '../acessorioTransferencia/acessorioTransferencia';
import ValePedagio from '../valePedagio/valePedagio';
import ValePedagioPeriodo from '../valePedagioPeriodo/valePedagioPeriodo';
import ValePedagioMotorista from '../valePedagioMotorista/valePedagioMotorista';
import ValePedagioSaldo from '../valePedagioMotorista/valePedagioSaldo';
import AcessorioSaldo from '../acessorioSaldo/acessorioSaldo';
import AcessorioHistorico from '../acessorioHistorico/acessorioHistorico';
import ValePedagioMotivo from '../valePedagioMotivo/valePedagioMotivo';
import ValePedagioSaldoPeriodo from '../valePedagioMotorista/valePedagioSaldoPeriodo';
import ValePedagioMotivoPeriodo from '../valePedagioMotorista/valePedagioMotivoPeriodo';
import DespesaTipo from '../despesaTipo/despesaTipo';
import Despesa from '../despesa/despesa';
import Reports from '../reports/reports';
import ReportsHistorico from '../reportsHistorico/reportsHistorico';
import ReportsConformidade from '../reportsConformidade/reportsConformidade';
import ReportsIntegracaoGM from '../reportsIntegracaoGM/reportsIntegracaoGM';
import ReportsIntegracaoTetraPak from '../reportsIntegracaoTetraPak/reportsIntegracaoTetraPak';
import ReportsTrack from '../reportsTrack/reportsTrack';
import ComissaoTipo from '../comissaoTipo/comissaoTipo';
import ComissaoOperacao from '../comissaoOperacao/comissaoOperacao';
import ComissaoApuracao from '../comissaoApuracao/comissaoApuracao';
import ComprovanteEntrega from '../comprovanteEntrega/comprovanteEntrega';
export default props => {

	if (!props.usuarioCarregado.acesso_cliente) {
		
		return (
			<div id='id-content-wrapper' className='content-wrapper' style={{ paddingBottom: 0 }} >
				<Switch>
					<Route exact path='/' component={Dashboard} />
					<Route path='/usuario' component={Usuario} />
					<Route path='/usuarioCliente' component={UsuarioCliente} />
					<Route path='/usuarioPerfil' component={UsuarioPerfil} />
					<Route path='/grupo' component={Grupo} />
					<Route path='/motorista' component={Motorista} />
					<Route path='/MotoristaCnh' component={MotoristaCnh} />
					<Route path='/MotoristaCpf' component={MotoristaCpf} />
					<Route path='/premioFaixa' component={PremioFaixa} />
					<Route path='/ocorrenciaTipo' component={OcorrenciaTipo} />
					<Route path='/ocorrenciaAcao' component={OcorrenciaAcao} />
					<Route path='/ocorrenciaStatus' component={OcorrenciaStatus} />
					<Route path='/ocorrencia' component={Ocorrencia} />
					<Route path='/ocorrenciaDocumentoViagem' component={OcorrenciaDocumentoViagem} />
					<Route path='/premiacao' component={Premiacao} />
					<Route path='/documento' component={Documento} />
					<Route path='/operacao' component={Operacao} />
					<Route path='/operacaoPerfil' component={OperacaoPerfil} />
					<Route path='/operacaoDespesa' component={OperacaoDespesa} />
					<Route path='/documentoViagem' component={DocumentoViagem} />
					<Route path='/documentoViagemComprovacao' component={DocumentoViagemComprovacao} />
					<Route path='/documentoViagemComprovada' component={DocumentoViagemComprovada} />
					<Route path='/documentoViagemFinalizada' component={DocumentoViagemFinalizada} />
					<Route path='/unidade' component={Unidade} />
					<Route path='/veiculo' component={Veiculo} />
					<Route path='/acessorioTipo' component={AcessorioTipo} />
					<Route path='/acessorioEvento' component={AcessorioEvento} />
					<Route path='/acessorioSaida' component={AcessorioSaida} />
					<Route path='/acessorioEntrada' component={AcessorioEntrada} />
					<Route path='/acessorioTransferencia' component={AcessorioTransferencia} />
					<Route path='/valePedagio' component={ValePedagio} />
					<Route path='/valePedagioMotivo' component={ValePedagioMotivo} />
					<Route path='/valePedagioPeriodo' component={ValePedagioPeriodo} />
					<Route path='/valePedagioMotorista' component={ValePedagioMotorista} />
					<Route path='/valePedagioSaldo' component={ValePedagioSaldo} />
					<Route path='/acessorioSaldo' component={AcessorioSaldo} />
					<Route path='/acessorioHistorico' component={AcessorioHistorico} />
					<Route path='/valePedagioSaldoPeriodo' component={ValePedagioSaldoPeriodo} />
					<Route path='/valePedagioMotivoPeriodo' component={ValePedagioMotivoPeriodo} />
					<Route path='/despesaTipo' component={DespesaTipo} />
					<Route path='/despesa' component={Despesa} />
					{props.usuarioCarregado.reports &&
					<Route path='/reports' component={Reports} />}
					{props.usuarioCarregado.reports &&
					<Route path='/reportsHistorico' component={ReportsHistorico} />}
					{props.usuarioCarregado.reports &&
					<Route path='/reportsIntegracaoGM' component={ReportsIntegracaoGM} />}
					{props.usuarioCarregado.reports &&
					<Route path='/reportsIntegracaoTetraPak' component={ReportsIntegracaoTetraPak} />}
					{props.usuarioCarregado.reports &&
					<Route path='/reportsConformidade' component={ReportsConformidade} />}
					{props.usuarioCarregado.track &&
					<Route path='/reportsTrack' component={ReportsTrack} />}
					<Route path='/comissaoTipo' component={ComissaoTipo} />
					<Route path='/comissaoOperacao' component={ComissaoOperacao} />
					<Route path='/comissaoApuracao' component={ComissaoApuracao} />
					<Route path='/comprovanteEntrega' component={ComprovanteEntrega} />
					<Redirect from='*' to='/' />
				</Switch>
			</div>
		);
	} else {
		return (
			<div id='id-content-wrapper' className='content-wrapper' style={{ paddingBottom: 0 }} >
				<Switch>

					{(props.usuarioCarregado && props.usuarioCarregado.track) &&
					<Route exact path='/' component={ReportsTrack} />}

					{(props.usuarioCarregado && props.usuarioCarregado.reports) && 					
					<Route exact path={props.usuarioCarregado.track ? '/reports' : '/'} component={Reports} />}

					<Redirect from='*' to='/' />
				</Switch>
			</div>
		);
	}
};
