import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './acessorioSaidaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import InputPesquisa from '../common/form/inputPesquisa';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';

import { 
	setModoTela, initForm, setFiltro, salvar, excluir, getLista, 
	getListaTipo, getListaEvento, getListaUnidade, getListaVeiculo, getListaMotorista,
	gerarFormulario
} from './acessorioSaidaActions';

class AcessorioSaida extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		filtro: {
			id_tipo: null,
			id_evento: null,
			id_unidade: null
		},
		ordenacao: {
			datahora_saida: 1,
			nome_tipo: 0,
			local: 0,
			nome_evento: 0,
			unidade_placa: 0,
			nome_motorista: 0,
			quantidade: 0
		}
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
		this.props.getListaEvento();
		this.props.getListaUnidade();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getOrdenacao(ordenacao) {
		return ordenacao == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	filtrar() {
		
		let lista = this.props.lista.filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida)}
					${item.nome_tipo}
					${item.id_unidade ? 'Unidade' : 'Veículo'}
					${item.nome_evento}
					${item.nome_unidade || item.placa_veiculo}
					${item.nome_motorista}
					${parseInt(item.quantidade)}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		});

		lista = lista.filter(item => {

			if (this.state.filtro.id_tipo && this.state.filtro.id_tipo != item.id_tipo) {
				return false;
			}
			if (this.state.filtro.id_evento && this.state.filtro.id_evento != item.id_evento) {
				return false;
			}
			if (this.state.filtro.id_unidade && this.state.filtro.id_unidade != item.id_unidade) {
				return false;
			}
			
			return true;
		});
		
		if (this.state.ordenacao.datahora_saida) {
			lista.sort((a, b) => this.state.ordenacao.datahora_saida == 1 
				? (a.datahora_saida > b.datahora_saida ? 1 : -1) 
				: (a.datahora_saida > b.datahora_saida ? -1 : 1));
		} else if (this.state.ordenacao.nome_tipo) {
			lista.sort((a, b) => this.state.ordenacao.nome_tipo == 1 
				? (a.nome_tipo > b.nome_tipo ? 1 : -1) 
				: (a.nome_tipo > b.nome_tipo ? -1 : 1));
		} else if (this.state.ordenacao.local) {
			lista.sort((a, b) => this.state.ordenacao.local == 1 
				? ((a.id_unidade ? 'Unidade' : 'Veículo') > (b.id_unidade ? 'Unidade' : 'Veículo') ? 1 : -1) 
				: ((a.id_unidade ? 'Unidade' : 'Veículo') > (b.id_unidade ? 'Unidade' : 'Veículo') ? -1 : 1));
		} else if (this.state.ordenacao.nome_evento) {
			lista.sort((a, b) => this.state.ordenacao.nome_evento == 1 
				? (a.nome_evento > b.nome_evento ? 1 : -1) 
				: (a.nome_evento > b.nome_evento ? -1 : 1));
		} else if (this.state.ordenacao.unidade_placa) {
			lista.sort((a, b) => this.state.ordenacao.unidade_placa == 1 
				? ((a.nome_unidade || a.placa_veiculo) > (b.nome_unidade || b.placa_veiculo) ? 1 : -1) 
				: ((a.nome_unidade || a.placa_veiculo) > (b.nome_unidade || b.placa_veiculo) ? -1 : 1));
		} else if (this.state.ordenacao.nome_motorista) {
			lista.sort((a, b) => this.state.ordenacao.nome_motorista == 1 
				? ((a.nome_motorista || '') > (b.nome_motorista || '') ? 1 : -1) 
				: ((a.nome_motorista || '') > (b.nome_motorista || '') ? -1 : 1));
		} else if (this.state.ordenacao.quantidade) {
			lista.sort((a, b) => this.state.ordenacao.quantidade == 1 
				? (a.quantidade > b.quantidade ? 1 : -1) 
				: (a.quantidade > b.quantidade ? -1 : 1));
		}

		return lista;

	}

	montarExcel(lista) {

		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ExcelSheet data={lista.map(item => ({
				...item,
				datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
				local: item.id_unidade ? 'Unidade' : 'Veículo',
				unidade_placa: item.nome_unidade || item.placa_veiculo,
				quantidade: parseInt(item.quantidade)
			}))} name='Acessórios - Saída'>
				{[
					{ componente: <ExcelColumn key={1} label='Data/Hora' value='datahora_saida' /> },
					{ componente: <ExcelColumn key={2} label='Tipo' value='nome_tipo' /> },
					{ componente: <ExcelColumn key={3} label='Local' value='local' /> },
					{ componente: <ExcelColumn key={4} label='Evento' value='nome_evento' /> },
					{ componente: <ExcelColumn key={5} label='Nome/Placa' value='unidade_placa' /> },
					{ componente: <ExcelColumn key={6} label='Motorista' value='nome_motorista' /> },
					{ componente: <ExcelColumn key={7} label='Qtd.' value='quantidade' type='number' /> }
				].filter(item => {
					return true;
				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

	lista() {

		const ExcelFile = ReactExport.ExcelFile;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<InputPesquisa
							cols='12 6 3 1'
							label='Pesquisar'
							minWidth={200}
							onChange={value => this.setState({ ...this.state, pesquisar: value })} />

						<Select
							label='Acessório'
							options={this.props.listaTipo}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.id_tipo}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_tipo: value
									}
								});
							}} />
						
						<Select
							label='Evento'
							options={this.props.listaEvento}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.id_evento}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_evento: value
									}
								});
							}} />

						<Select
							label='Unidade'
							options={this.props.listaUnidade}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.id_unidade}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_unidade: value
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.props.filtro.data_inicial}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.props.filtro.data_final}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />

						<div style={{ marginTop: 8 }}>
							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.getLista();
								}}>
								<i class='fas fa-sync-alt'></i> Atualizar
							</a>
							<ExcelFile
								filename={'Acessórios - Saída'}
								element={
									<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
										<i class='fas fa-file-excel'></i> Exportar
									</a>
								}>
								{this.montarExcel(this.filtrar())}													
							</ExcelFile>

							<a class='btn btn-app bg-danger' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.setModoTela('cadastro');
									this.props.initForm();
								}}>
								<i class='fas fa-arrow-down'></i> Remover
							</a>
						</div>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Table responsive border alturaFixa striped 
						maxHeight={window.innerWidth < 576 ? '100%' : (
							window.innerWidth < 768 ? window.innerHeight - 370 : (
								window.innerWidth < 1558 ? window.innerHeight - 280 : window.innerHeight - 200
							)
						)}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th alignCenter sticky width={100} color={'#1F3066'} small
									onClick={() => this.setState({ ...this.state, ordenacao: { datahora_saida: this.getNovaOrdenacao(this.state.ordenacao.datahora_saida) }})}>
									Data/Hora {this.getOrdenacao(this.state.ordenacao.datahora_saida)}
								</Th>
								<Th alignCenter sticky width={100} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { nome_tipo: this.getNovaOrdenacao(this.state.ordenacao.nome_tipo) }})} >
									Tipo {this.getOrdenacao(this.state.ordenacao.nome_tipo)}
								</Th>
								<Th alignCenter sticky width={200} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { local: this.getNovaOrdenacao(this.state.ordenacao.local) }})} >
									Local {this.getOrdenacao(this.state.ordenacao.local)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { nome_evento: this.getNovaOrdenacao(this.state.ordenacao.nome_evento) }})} >
									Evento {this.getOrdenacao(this.state.ordenacao.nome_evento)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { unidade_placa: this.getNovaOrdenacao(this.state.ordenacao.unidade_placa) }})} >
									Nome / Placa {this.getOrdenacao(this.state.ordenacao.unidade_placa)}
								</Th>
								<Th alignCenter sticky width={200} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { nome_motorista: this.getNovaOrdenacao(this.state.ordenacao.nome_motorista) }})} >
									Motorista {this.getOrdenacao(this.state.ordenacao.nome_motorista)}
								</Th>
								<Th alignCenter sticky width={80} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { quantidade: this.getNovaOrdenacao(this.state.ordenacao.quantidade) }})} >
									Qtd. {this.getOrdenacao(this.state.ordenacao.quantidade)}
								</Th>
								<Th width={120} sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.filtrar().map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida)}</Td>
									<Td alignCenter small>{item.nome_tipo}</Td>
									<Td alignCenter small>{item.id_unidade ? 'Unidade' : 'Veículo'}</Td>
									<Td alignCenter small>{item.nome_evento}</Td>
									<Td alignCenter small>{item.nome_unidade || item.placa_veiculo}</Td>
									<Td alignCenter small>{item.nome_motorista}</Td>
									<Td alignCenter  small>{parseInt(item.quantidade)}</Td>
									{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_acessorios ? (
										<Td alignCenter minWidth={120} small>
											<ButtonTable
												type={'primary'}
												icon={'fas fa-file-alt'}
												visible={this.state.linhaSelecionada == item.id}
												title='Emitir formulário'
												disabled={item.id_unidade}
												event={() => {
													this.props.gerarFormulario({
														...item,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
														data: DateFormat.formatarDataSqlParaTela(item.datahora_saida),
														quantidade: parseInt(item.quantidade),
														unitario: '56,00',
														total: FormatUtils.formatarValorTela((parseInt(item.quantidade) * 28) * 2, 2)
													});
												}} />

											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
														quantidade: parseInt(item.quantidade)
													});
													this.props.initForm({
														...item,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
														quantidade: parseInt(item.quantidade)
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
														quantidade: parseInt(item.quantidade)
													});
													this.props.initForm({
														...item,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
														quantidade: parseInt(item.quantidade)
													});
												}} />
										</Td>
									) : (
										<Td alignRight minWidth={100}></Td>
									)}
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.acessorioSaida.modoTela,
	filtro: state.acessorioSaida.filtro,
	aguardando: state.acessorioSaida.aguardando,
	lista: state.acessorioSaida.lista,
	listaTipo: state.acessorioSaida.listaTipo,
	listaEvento: state.acessorioSaida.listaEvento,
	listaUnidade: state.acessorioSaida.listaUnidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, setFiltro, salvar, excluir, getLista, 
	getListaTipo, getListaEvento, getListaUnidade, getListaVeiculo, getListaMotorista,
	gerarFormulario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioSaida);
